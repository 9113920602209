<template>
    <v-card
      raised
      color="blue-grey lighten-5 elevation-12"
    >
      <v-container
        fluid
        grid-list-lg
      >
      <v-card-title class="v-card-title"> 
        <h3>
          {{ member.lastname }}{{ hasName ? ', ' : ''}} {{ member.firstname }} 
        </h3>
      </v-card-title>
      <v-img 
        v-if="!edit"
        style="border-radius: 12px;" 
        :src="member.picture || `https://via.placeholder.com/50/0088FF/FFFFFF?text=?`"
        aspect-ratio="1.0"
      >
      </v-img>
      <v-card-text>
        <div v-if="edit">
          <div class="line">
            <v-label>First Name</v-label>
            <input 
              type="text" 
              placeholder="first name" 
              v-model="member.firstname"
            />
          </div>
          <div class="line">
            <v-label>Last Name</v-label>
            <input 
              type="text" 
              placeholder="last name" 
              v-model="member.lastname"
            />
          </div>
        </div>
        <div class="line">
          <v-label>Username </v-label>
          <span class="username" v-if="!edit">{{ member.username }}</span>
          <input 
            v-if="edit"
            ref="editUsername"
            type="text" 
            placeholder="username" 
            v-model="member.username"
          />
        </div>
        <div class="line" v-if="edit">
          <v-label>eMail</v-label>
          <input 
            type="text" 
            placeholder="email" 
            v-model="member.email"
          />
        </div>
        <div class="line" v-if="edit">
          <v-label>Picture URL</v-label>
          <input 
            type="text" 
            placeholder="picture" 
            v-model="member.picture"
          />
        </div>
        <div class="line">
          <v-label>Gender</v-label>
          <select 
            :disabled="!edit"
            :value="member.gender"
            @change="changeGender"
          >
            <option value="f">Femenine</option>
            <option value="m">Masculine</option>
            <option value="o">Other</option>
          </select>
        </div>
        <div class="line">
          <v-label>Country </v-label>
          <Countries
            :disabled="!edit"
            :initValue="member.countrycode"
            @changeCountry="changeCountry"
          >
          </Countries>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="!edit" 
          fab dark small absolute bottom left 
          color="green darken-3"
          @click="() => {edit = true; /*this.$refs.Username.focus() */}"
          :title="`Edit ${member.username}`"
        >
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn
          v-if="edit" 
          fab dark small absolute bottom left
          color="green darken-3"
          @click="saveEdit"
          :title="`Save ${member.username}`"
        >
          <v-icon>save</v-icon>
        </v-btn>
        <v-btn
          v-if="edit" 
          fab dark small absolute bottom right 
          color="red darken-3"
          @click="cancelEdit"
          :title="`Cancel editing ${member.username}`"
        >
          <v-icon>cancel</v-icon>
        </v-btn>
        <v-btn
          v-if="!edit" 
          fab dark small absolute bottom right
          color="red darken-3"
          @click="$emit('delete', member)"
          :title="`Delete ${member.username}`"
        >
          <v-icon>delete</v-icon>
        </v-btn>
      </v-card-actions>
      </v-container>
    </v-card>
</template>

<script>
import Countries from '@/components/CountriesAlpha2'

export default {
    props: {init: Object},
    components: { Countries },
    data() {
      return {
        member: {...this.init},
        edit: false
      }
    },
    computed: {
      hasName () {return !!(this.member.lastname && this.member.firstname)}
    },
    methods: {
      cancelEdit() {
        this.member = {...this.init}
        this.edit = false
      },
      saveEdit() {
        this.$emit('change', this.member)
        this.edit = false
      },
      changeCountry(payload) {
        console.log(`Country code: ${payload.countrycode}\tCountry: ${payload.country}`)
        this.member.countrycode = payload.countrycode
        this.member.country = payload.country || ''
      },
      changeGender(ev) {
        this.member.gender = ev.target.value
      }
    },
}
</script>

<style>
  .v-card-title {
    color: steelblue;
  }
  .right-div {
    text-align: right;
  }
  .username {
    font-weight: bold;
  }
  .line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.15em;
  }

  .bottom {
    position: relative;
    bottom: 0.5em;
  }

  select {
    color:black;
    background-color: white;
    max-width: 50%;
    border: solid 1px slategray;
    padding-left: 5px;
  }
</style>
