<template>
  <div>
    <v-container 
      grid-list-xl
      fluid
      class="blue-grey lighten-5" 
    >

      <v-layout row wrap justify-space-between align-baseline ml-5 mt-1 mb-1>
        <v-flex xs8 md2>
          <h2 class="display-1 green--text text--darken-4">Members</h2>
        </v-flex>
        <v-flex xs8 md2 mb-2>   
          <v-label>Sort by &nbsp;&nbsp;&nbsp; </v-label>
          <select
            style="min-width: 8em;"
            v-model="orderBy"
            @change="sort"
          >
            <option value="username">Username</option>
            <option value="lastname">Last Name</option>
            <option value="firstname">First Name</option>
            <option value="countrycode">Country</option>
            <option value="email">eMail</option>
          </select>
        </v-flex>
        <v-flex xs8 md2>
            <p><v-label>Order &nbsp;&nbsp;&nbsp; </v-label></p>
            <p>
              <input type="radio" value="asc" v-model="order" @change="sort">
              <v-label>&nbsp;Asc &nbsp;&nbsp;&nbsp;</v-label>
            </p>
            <p>
              <input type="radio" value="desc" v-model="order" @change="sort">
              <v-label>&nbsp;Desc</v-label>
            </p>
        </v-flex> 
        <v-flex xs8 md2>
          <v-btn 
            fab dark small 
            color="green darken-3" 
            class="text-md-right"
            @click="addMember"
            title="Add member"
          >
            <v-icon>add</v-icon>
          </v-btn>
        </v-flex>  
      </v-layout>        

      <v-layout row wrap>     
        <v-flex 
          xs12
          sm6
          md4
          lg3
          xl2 
          class="show-cardz"
          v-for="m in members" 
          :key="m.id" 
        >
          <MemberCard 
            class="v-card"
            :init="m"
            @change="(nm) => changeMember(nm)"
            @delete="(nm) => deleteMember(nm)"
          >
          </MemberCard>
        </v-flex>
      </v-layout> 

    </v-container>
  </div>
</template>

<script>

import db from '@/firebase/init'
import MemberCard from '@/components/MemberCard'
//import DummyCard from '@/components/DummyCard'
import Countries from '@/components/CountriesAlpha2'

export default {
    name: 'members',
    components: { MemberCard, Countries },
    data() {
        return {
            members: [],
            orderBy: 'countrycode',
            order: 'asc',
        }
    },
    methods: {
        deleteMember(member) {
            if (!member.id) {
                return this.members = this.members.slice(0, -1)
            }
            document.body.style.cursor = 'wait'
            db.collection('members').doc(member.id).delete()
            .then(() => {
                this.members = this.members.filter(m => {
                    return m.id !== member.id
                })
            })
            .catch(err => console.log(err))
            .finally(() => document.body.style.cursor = 'default')
        },
        addMember() {
            this.members = [...this.members, {username: ""}]
            document.body.scrollTop = document.body.scrollHeight
        },
        changeMember(member) {
            //console.log(member.username)
            document.body.style.cursor = 'wait'
            if (member.id) {
              db.collection('members').doc(member.id).update(member)
              .then(() => console.log('Update succesful!'))
              .catch((err) => console.log(err))
              .finally(() => document.body.style.cursor = 'default')
            }
            else {
                this.members = this.members.filter((m) => !!m.id)
                db.collection('members').add(member)
                .then((ref) => {
                    member.id = ref.id
                })
                .catch(err => console.log(err))
                .finally(() => document.body.style.cursor = 'default')
            }
        },

        sort() {
          this.members.sort((m1, m2) => {
            // console.log(m1[this.orderBy], m2[this.orderBy])
            if (this.order === 'asc') {
              return m2[this.orderBy] <= m1[this.orderBy] ? 1 : -1
            }
            else {
              return m2[this.orderBy] >= m1[this.orderBy] ? 1 : -1
            }
          })
        },

        query() {
        document.body.style.cursor = 'wait'
        this.members = []   
        const ref = db.collection('members').orderBy(this.orderBy, this.order)
        
        ref.onSnapshot(ss => {
          document.body.style.cursor = 'default'
          ss.docChanges().forEach((changeObj) => {
            if (changeObj.type === "added") {
              let newMember = {...changeObj.doc.data()}
              newMember.id = changeObj.doc.id
              this.members.push(newMember)
            }
            if (changeObj.type === "modified") {
              console.log( 'Modified', changeObj)
              let modifMember = {...changeObj.doc.data()}
              modifMember.id = changeObj.doc.id
              this.members = [...this.members.filter(m => m.id !== modifMember.id), modifMember]
            }
            if (changeObj.type === "deleted") {
              console.log( 'Deleted', changeObj.doc.data())
              this.members = this.members.filter(m => m.id !== changeObj.doc.id)
            }
            this.sort()
          })
        })

      /*
        ref.get()
        .then(ss => {
            ss.forEach( doc => {
                let newMember = {...doc.data()}
                newMember.id = doc.id
                // console.log(`${newMember.username} - ${newMember.id}`)
                this.members.push(newMember)
            })
            this.sort()
            window.members = this.members

        })
        .catch((err) => console.log(err))
        .finally(() => document.body.style.cursor = 'default')

        */
      }
      
    },
    
    created() {

      this.query()
    },

    destroyed() {
        console.log("Getting out of members view")
        document.body.style.cursor = 'default'
        
    }
}
</script>

<style>

 .members h2 {
    color: steelblue;
  }

 .container {
     max-width: 80%;
 }

 hr {
     color: steelblue;
     margin-bottom: 1.5em;
 }

 .rowx {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     margin-bottom: 1em;
 }

 .show-cards {
     display: grid;
     grid-template-columns: 1fr 1fr 1fr;
     grid-gap: 50px;
     border-top: none;
     border-bottom: none;
     padding-top: 10px;
     padding-bottom: 10px;
 }

 .v-card {
     border-radius: 15px;
     margin-bottom: 3em;
 }

 .bordered {
   border: solid 1px slategrey;
   border-radius: 5px;
 }

</style>




