import { render, staticRenderFns } from "./Members.vue?vue&type=template&id=79ee6bbe&"
import script from "./Members.vue?vue&type=script&lang=js&"
export * from "./Members.vue?vue&type=script&lang=js&"
import style0 from "./Members.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/home/ernesto/tera/Dropbox/programas/2019/chess-club-1/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VLabel } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
installComponents(component, {VBtn,VContainer,VFlex,VIcon,VLabel,VLayout})
