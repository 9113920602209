import firebase from 'firebase'
import firestore from 'firebase/firestore'

// Initialize Firebase
var config = {
  apiKey: "AIzaSyAaznJdl7zaIKwdHTc-bn5ahRusTPGYISI",
  authDomain: "chessclub-9e736.firebaseapp.com",
  databaseURL: "https://chessclub-9e736.firebaseio.com",
  projectId: "chessclub-9e736",
  storageBucket: "chessclub-9e736.appspot.com",
  messagingSenderId: "365568917915",
  appId: "1:365568917915:web:17e72263488b8a6e"};
const firebaseApp = firebase.initializeApp(config);
//firebaseApp.firestore().settings({})

// export firestore database
export default firebaseApp.firestore()
